<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    
    <iframe width="100%" height="100%" src="https://datastudio.google.com/embed/reporting/fb6e9a7c-498a-4dc7-9ef5-46c640ffbea9/page/3nn6B" frameborder="0" style="position: absolute; height: 100%; border: none" allowfullscreen></iframe>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Looker',
    components: {},
    mixins: [],
    data () {
      return {
        error: '',
        campaign: null,
        aid: null,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
      },
    },
    created () {
    },
    mounted () {
    },
    methods: {

    },
  }
</script>

